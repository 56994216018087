<template>
	<v-content color="primary">
		<v-snackbar
				v-model="snackbar"
				:timeout="timeout"
				top
		>
			{{ text }}
			<v-btn
					flat
					@click="snackbar = false"
			>
				Close
			</v-btn>
		</v-snackbar>
		<v-container fluid>
			<v-card class="mt-3">
				<v-card-title>
					<v-text-field
							v-model="searchLogs"
							append-icon="search"
							:label='t("Search")'
							single-line
							hide-details
					>
					</v-text-field>
				</v-card-title>
			</v-card>
			<v-card class="my-3">
				<v-subheader>{{t('Logs')}}</v-subheader>
				<v-data-table
					:pagination.sync="logsOptions"
					:headers="headersLogs"
					:items="details"
					items-per-page="5"
					class="elevation-1"
					:search="searchLogs"
					:rows-per-page-text="t('Rows per page')"
					:rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
				>
					<template slot="items" slot-scope="props">
						<td class="text-xs-left no-wrap">
							{{ $moment.unix(props.item.date_timestamp).format('YYYY-MM-DD HH:mm:ss') }}
						</td>
						<td class="text-xs-left no-wrap">{{ props.item.log }}</td>
					</template>
					<template slot="pageText" slot-scope="item">
						{{t('Elements')}} {{item.pageStart}} - {{item.pageStop}}, {{t('total')}}: {{item.itemsLength}}
					</template>
					<template slot="no-data">
						<v-subheader>{{t('No data available')}}</v-subheader>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
	</v-content>
</template>
<script>
import axios from 'axios';

export default {
	name: 'Logs',
	data() {
		return {
			searchLogs:'',
			timeout: 5000,
			details: [],
			text: "Error while getting logs",
			snackbar: false,
			logsOptions: {
				sortBy: 'date_timestamp',
				descending: true,
			},
			headersLogs: [
				{
					text: this.$translate.locale['Date'],
					value: 'date_timestamp'
				},
				{
					text: this.$translate.locale['Header'],
					align: 'left',
					value: 'log',
				},
			],
		}
	},
	methods: {
		getData() {
			axios.get(`/api/reseller/logs`)
				.then(response => {
					if (response.status === 200) {
						this.details = response.data
					} else {
						this.snackbar = true;
					}
				})
				.catch(error => {
					this.snackbar = true;
				})
		}
	},
	mounted() {
		this.getData()
	}
}
</script>
